export const delay = (delay: number = 0, fn: () => void) => {
    const timerId = setTimeout(() => {
        fn();
    }, delay);

    const cancelDelay = () => {
        clearTimeout(timerId);
        fn();
    };

    return cancelDelay;
};
