import React from 'react';
import { ParsedSubCategory } from '../../contracts/parsers/IParser';
import { MarketMapping } from '@draftkings/component-builder/src/components/MarketMapping/MarketMapping';
import { Collapsible } from '@draftkings/component-builder/src/components/Collapsible/Collapsible';
import { ProductConfig } from '@draftkings/event-page-widget-contracts/src/ProductConfig';
import { TrackEventFunction } from '@draftkings/event-page-widget-contracts/src/Tracking';
import { usePlayerPageProps } from '../../contexts/PlayerPageContext/usePlayerPageProps';

import './index.scss';

type MarketboardProps = {
    subcategories: ParsedSubCategory[];
    toggleIsExpanded: (subcategoryId: string) => void;
    productConfig: ProductConfig;
    trackEvent: TrackEventFunction;
    consumerVersion: string;
};

export const Marketboard = React.memo(
    ({
        subcategories,
        children,
        toggleIsExpanded,
        productConfig,
        trackEvent,
        consumerVersion
    }: React.PropsWithChildren<MarketboardProps>) => {
        const { playerPageConfig, getPlayerLink, onPlayerLinkClick } = usePlayerPageProps();

        return (
            <div className="market-layout">
                <div className="market-layout__content">
                    {children}
                    <div className="market-layout__content__main">
                        {subcategories.map((subcategory: ParsedSubCategory, i: number) => {
                            return (
                                <Collapsible
                                    key={`subcategory-${subcategory.id}-${i}`}
                                    trigger={{
                                        data: {
                                            title: subcategory.name
                                        }
                                    }}
                                    content={subcategory.marketsCells.map((market) => (
                                        <MarketMapping
                                            consumerVersion={consumerVersion}
                                            widgetVersion={APP_VERSION}
                                            {...market}
                                            key={`market-${market.key}`}
                                            productConfig={productConfig}
                                            trackEvent={trackEvent}
                                            playerPageConfig={playerPageConfig}
                                            getPlayerLink={getPlayerLink}
                                            onPlayerLinkClick={onPlayerLinkClick}
                                        />
                                    ))}
                                    isExpanded={subcategory.isExpanded}
                                    contentId={subcategory.id}
                                    contentType="subcategory"
                                    onToggleExpandClick={() => {
                                        toggleIsExpanded(subcategory.id);
                                    }}
                                    animationConfig={{
                                        duration: 300,
                                        ease: 'ease-in-out'
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
);
