import { ITabSwitcherController } from '@draftkings/dk-tab-switcher/contracts';
import { SBMessageBus } from '@draftkings/event-page-widget-contracts/src/MessageBus';
import { Types as ScoreboardTypes } from '@draftkings/scoreboards';
import { makeObservable, computed, observable, action, reaction } from 'mobx';
import { IParser } from '../../contracts/parsers/IParser';
import { IState, StateOptions } from '../../contracts/state';
import { PropsWithChildren } from 'react';
import { getDefaultValue, GOLF_SPORT_ID, hasAnyPrePacks, hasSgpToggle as hasSgpToggleFn } from '../../helpers';
import { EventPageWidgetConfig } from '@draftkings/event-page-widget-contracts/src/EventPageWidgetConfig';
import { QuickSgpParams } from '@draftkings/event-page-widget-contracts/src/QuickSgp';
import { generatePlayerURL } from '../../helpers/generatePlayerURL/generatePlayerURL';
import { PlayerData, PlayerPageConfig } from '@draftkings/event-page-widget-contracts/src/PlayerPage';
import { Logos } from '@draftkings/event-page-widget-contracts/src/Logos';
import { EventMedia } from '@draftkings/dk-data-layer/lib-esm/contracts';
import { DataCondition } from '@draftkings/widgets-core/src/utils/Condition';
import { ICondition, Noop } from '@draftkings/widgets-core/src/contracts';
import { isMobile } from '../../temp';

type EventPageStateMobx =
    | 'events'
    | 'tabs'
    | 'tabName'
    | 'onTabClick'
    | 'isStatsEnabled'
    | 'setStatsMode'
    | 'betslipSelectionsIds'
    | 'onToggleSgpMode'
    | 'hasSgpToggle';

export class EventPageState implements IState {
    private parser: IParser;
    private messageBus: SBMessageBus;
    private tabName: string | undefined;
    private renderQuickSgp: (quickSgpParams: QuickSgpParams) => JSX.Element;
    private renderStats: (eventId: string, outcomes: string[]) => JSX.Element;
    private renderStrapline: () => JSX.Element;
    private isStatsEnabled: boolean;
    private logos: Logos;
    private disposers: Noop[];
    productConfig: EventPageWidgetConfig['productConfig'];
    private readonly condition: ICondition;
    isSgpEnabled: boolean;
    tabSwitcherController: ITabSwitcherController;
    getNavLink: (routeDetails: PropsWithChildren<{ to: string }>) => JSX.Element;
    getTeamLogo: (props: ScoreboardTypes.TeamLogoProps) => JSX.Element;
    getDateCell: (props: ScoreboardTypes.DateCellProps) => JSX.Element;
    staticS3Host: string;
    constructor(options: StateOptions) {
        this.parser = options.parser;
        this.messageBus = options.messageBus;
        this.tabSwitcherController = options.tabSwitcherController;
        this.tabName = options.tabName;
        this.getNavLink = options.getNavLink;
        this.getTeamLogo = options.getTeamLogo;
        this.getDateCell = options.getDateCell;
        this.renderQuickSgp = options.renderQuickSgp;
        this.renderStats = options.renderStats;
        this.renderStrapline = options.renderStrapline;
        this.staticS3Host = options.staticS3Host;
        this.productConfig = options.productConfig;
        this.isStatsEnabled = options.hasStats;
        this.isSgpEnabled = options.isSgpEnabled;
        this.logos = options.logos;
        this.disposers = [];
        this.condition = options.condition;
        this.setSgpMode = this.setSgpMode.bind(this);
        makeObservable<typeof this, EventPageStateMobx>(this, {
            events: computed,
            tabs: computed,
            isTeamSwap: computed,
            showOverlay: computed,
            selectedTab: computed,
            betslipSelectionsIds: computed,
            tabName: observable,
            isStatsEnabled: observable,
            onTabClick: action.bound,
            setStatsMode: action.bound,
            isSgpEnabled: observable,
            hasSgpToggle: computed,
            onToggleSgpMode: action.bound,
            isError: computed,
            isLoaded: computed,
            isLoading: computed
        });
    }

    get isTeamSwap() {
        return this.parser.isTeamSwap;
    }

    get events() {
        return [...this.parser.events.values()];
    }

    get betslipSelectionsIds() {
        return [...this.parser.betslipSelections.keys()];
    }

    get tabs() {
        const additionalTabs = [
            {
                id: 'artificial-id-2',
                name: 'Quick Sgp',
                isSelected: 'quick sgp' === this.tabName?.toLowerCase(),
                subTabs: [],
                isDisabled: !hasAnyPrePacks(this.productConfig.prePacksConfig.eventPage, this.parser.tags)
            },
            {
                id: 'artificial-id-3',
                name: 'Stats',
                isSelected: 'stats' === this.tabName?.toLowerCase(),
                subTabs: [],
                isDisabled: !this.isStatsEnabled
            }
        ].filter((tab) => !tab.isDisabled);

        return [
            {
                id: 'artificial-id-1',
                name: 'Odds',
                isSelected: 'odds' === this.tabName?.toLowerCase() || additionalTabs.every((tab) => !tab.isSelected),
                subTabs: this.parser.categories,
                isDisabled: false
            },
            ...additionalTabs
        ];
    }

    get selectedTab() {
        return this.tabs.find((tab) => tab.isSelected)?.name;
    }

    get showOverlay() {
        return this.parser.showOverlay;
    }

    get hasSgpToggle(): boolean {
        return hasSgpToggleFn(this.productConfig, this.parser.tags);
    }

    get isPlayerPagesEnabled(): boolean {
        return (
            this.productConfig.playerPageConfig.isEnabled &&
            this.logos[this.parser.leagueId]?.EnablePlayerPages === 'true'
        );
    }

    get playerPageConfig(): PlayerPageConfig {
        return {
            isPlayerImagesEnabled:
                this.productConfig.isPlayerImagesEnabled &&
                this.logos[this.parser.leagueId]?.EnablePlayerImages === 'true',
            isPlayerPagesEnabled: this.isPlayerPagesEnabled
        };
    }

    setSgpMode() {
        const data = {
            isEnabled: true,
            sportEventId: getDefaultValue(this.events[0]?.id, '')
        };
        !isMobile() && this.onToggleSgpMode(data);
        this.tabName = 'Odds';
        this.messageBus.emit('sgp_mode_activate');
    }

    onToggleSgpMode(data: { isEnabled: boolean; sportEventId: string }) {
        this.isSgpEnabled = data.isEnabled;
    }

    onToggleExpandClick = (subcategoryId: string) => {
        this.parser.toggleIsExpanded(subcategoryId);
    };
    renderQuickSgpWrapper = () => {
        return this.renderQuickSgp({
            eventId: getDefaultValue(this.events[0]?.id, ''),
            eventName: getDefaultValue(this.events[0]?.name, ''),
            leagueId: getDefaultValue(this.events[0]?.leagueId, ''),
            tags: getDefaultValue(this.events[0]?.tags, []).slice()
        });
    };

    renderStatsWrapper = () => {
        return this.renderStats(getDefaultValue(this.events[0]?.id, ''), this.betslipSelectionsIds);
    };

    renderStraplineWrapper = () => {
        return this.renderStrapline();
    };

    getPlayerLink = (data: PlayerData) => {
        if (!this.isPlayerPagesEnabled) {
            return '';
        }

        return generatePlayerURL({
            sport: this.parser.sportName,
            league: this.parser.leagueName,
            name: data.playerName,
            id: data.playerId,
            productConfig: this.productConfig
        });
    };

    onPlayerLinkClick = (url: string) => {
        this.messageBus.emit('on_player_link_click', {
            url
        });
    };

    onTabClick = (tabName: string) => {
        if (this.selectedTab === tabName) {
            return;
        }

        if (tabName === 'Odds') {
            this.parser.loadData();
        } else {
            this.parser.resetCategory();
        }

        this.tabName = tabName;
        this.messageBus.emit('on_tab_navigation', {
            category: tabName
        });
    };

    onConditionDataChange = (value: number) => {
        if (this.isError && this.events.length > 0) {
            return;
        }

        this.messageBus.emit('on_event_page_widget_status', {
            value
        });
    };

    onSubtabClick = (categoryId: string, categoryName: string) => {
        this.parser.setCategoryId(categoryId);
        const category = this.selectedTab;

        if (category) {
            this.messageBus.emit('on_tab_navigation', {
                category,
                subcategory: categoryName
            });
        }
    };

    onRefreshButtonClick = (categoryId: string) => {
        this.parser.setCategoryId(categoryId);
    };

    setStatsMode = (data: { isEnabled: boolean }) => {
        this.isStatsEnabled = data.isEnabled;
    };

    tryEmitProviderActivateMessage = (media: EventMedia[] | undefined) => {
        if (!media) {
            return;
        }

        const { isEnabled } = this.productConfig.imgEventCentreConfig.eventPage;
        const { providerEventId: simplebetProviderEventId } =
            media.find((media) => media.providerName === 'Simplebet') || {};
        const { providerEventId: imgProviderEventId } = media.find((media) => media.providerName === 'IMG') || {};

        if (simplebetProviderEventId) {
            this.messageBus.emit('simplebet_activate', {
                mediaId: simplebetProviderEventId
            });
        }

        if (this.events[0]?.sportId !== GOLF_SPORT_ID) {
            return;
        }

        if (isEnabled && imgProviderEventId) {
            // temporary solution, providerEventId might contain many numbers split by _, only first is needed for the widget
            const imgEventId = imgProviderEventId.split('_')[0];
            this.messageBus.emit('img_activate', {
                imgEventId
            });
        }
    };

    tryEmitImgDeactivateMessage = () => {
        const { isEnabled } = this.productConfig.imgEventCentreConfig.eventPage;

        if (isEnabled) {
            this.messageBus.emit('img_deactivate');
        }

        this.messageBus.emit('simplebet_deactivate');
    };

    isHomePage = () => window.location.pathname === '/';

    buildTabNavigationUrl(category: string, subcategory?: string): string {
        if (this.isHomePage()) {
            return '';
        }

        const categoryName = this.formatQueryParam(category);
        const subcategoryName = subcategory ? this.formatQueryParam(subcategory) : '';

        const urlParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(Array.from(urlParams.entries()));

        const isSelectedTab = this.selectedTab === category;
        if (isSelectedTab && !subcategory) {
            const tabParams = new URLSearchParams(params);
            return tabParams.size ? `${window.location.pathname}?${tabParams.toString()}` : window.location.pathname;
        }

        const restParams = Object.fromEntries(
            Array.from(urlParams.entries()).filter(([key]) => key !== 'category' && key !== 'subcategory')
        );
        const newParams = new URLSearchParams({
            ...restParams,
            category: categoryName,
            ...(subcategoryName && { subcategory: subcategoryName })
        });

        return `${window.location.pathname}?${newParams.toString()}`;
    }

    formatQueryParam = (name: string) => name.toLowerCase().replace(/\s/g, '-');

    activate() {
        this.messageBus.on('stats_mode', this.setStatsMode);
        this.messageBus.on('sgp_mode', this.onToggleSgpMode);
        this.parser.activate();
        this.disposers = [
            reaction(() => this.events[0]?.media, this.tryEmitProviderActivateMessage),
            reaction(() => this.condition.value, this.onConditionDataChange)
        ];
    }

    deactivate() {
        this.messageBus.off('stats_mode', this.setStatsMode);
        this.messageBus.off('sgp_mode', this.onToggleSgpMode);
        this.disposers.forEach((disposer) => disposer());
        this.tryEmitImgDeactivateMessage();
        this.parser.deactivate();
    }

    get isError() {
        return this.condition.value === DataCondition.ERROR;
    }
    get isLoaded() {
        return this.condition.value === DataCondition.LOADED;
    }
    get isLoading() {
        return this.condition.value === DataCondition.LOADING;
    }
}
