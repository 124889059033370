import { BaseRetriever } from '@draftkings/widgets-core/src/retriever';
import { ContractTypes, Stadium } from '@draftkings/dk-data-layer';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { throttle } from 'throttle-debounce';
import {
    AddPayload,
    ConditionKeys,
    IEventPageRetriever,
    RemovePayload,
    RetrieverOptions,
    SportsbookData,
    UpdatePayload
} from '../../contracts/retrievers/IRetriever';
import { SBMessageBus } from '@draftkings/event-page-widget-contracts/src/MessageBus';
import { addUniqueTags, formatStadiumResponseData, getWebHeaders, delay } from '../helpers';
import { EVENT_STATUS_ERROR_CODES, isErrorType, isPopularTab, TRACKED_UPDATE_ENTITIES } from '../../helpers';
import { eventDeepMerge, marketDeepMerge, selectionDeepMerge } from '../deepMerge';
import { EventPageWidgetConfig } from '@draftkings/event-page-widget-contracts/src/EventPageWidgetConfig';
import { ICondition, IConditionManager } from '@draftkings/widgets-core/src/contracts';
import { DataCondition } from '@draftkings/widgets-core/src/utils/Condition';

// TODO: Remove as part of https://jira.dkcorpit.com/browse/SBW-4043
/* c8 ignore start */

type MarketboardRetrieverMobx =
    | 'setCategoryId'
    | 'resetCategory'
    | 'loadData'
    | 'onErrorCallback'
    | 'add'
    | 'change'
    | 'remove'
    | 'onDelayedMarketRemoval'
    | 'updateSelections'
    | '_categoryId';

export class EventPageRetriever
    extends BaseRetriever<ContractTypes.SubscriptionPartial, SportsbookData>
    implements IEventPageRetriever
{
    private initialData: boolean;
    private messageBus: SBMessageBus;
    private Stadium: Stadium;
    private SportsbookEvent: ContractTypes.SportsbooksEventConstructor;
    private serverData: SportsbookData | null;
    private logError: EventPageWidgetConfig['logError'];
    private selectionsStorage: Map<string, ContractTypes.SelectionUpdate>;
    private delayedRemovalMarketSelections: Map<string, string[]>;
    private delayedRemovalMarketHandlers: Map<string, () => void>;
    private delayedAddMarkets: Map<string, ContractTypes.Market>;
    private applySelectionsChanges: () => void;
    private _categoryId: string | undefined;
    private condition: IConditionManager<typeof ConditionKeys> & ICondition;
    private productConfig: EventPageWidgetConfig['productConfig'];
    private trackEvent: EventPageWidgetConfig['trackEvent'];
    private subCategories: Map<string, ContractTypes.SubCategory>;
    eventId: string;
    categoryName: string;

    constructor(options: RetrieverOptions) {
        super(options);
        this.eventId = options.eventId;
        this.initialData = options.initialData;
        this._categoryId = options.categoryId;
        this.categoryName = isPopularTab(options.categoryName) ? '' : options.categoryName;
        this.Stadium = new options.Stadium({
            ...getWebHeaders(options.consumerVersion)
        });
        this.SportsbookEvent = options.SportsbookEvent;
        this.messageBus = options.messageBus;
        this.serverData = options.serverData;
        this.logError = options.logError;
        this.onError = (err) => {
            const is404 = isErrorType(err) && err.errorStatus.code === EVENT_STATUS_ERROR_CODES.EVENT_NOT_FOUND;

            if (this.data.events.size && is404) {
                this.condition.set('EventPageRetriever', DataCondition.EMPTY);
            } else {
                this.condition.set('EventPageRetriever', DataCondition.ERROR);
            }

            !is404 &&
                this.logError({
                    statusCode: 'GetEventDataError',
                    description: err instanceof Error ? err.message : JSON.stringify(err),
                    eventId: this.eventId,
                    widgetVersion: APP_VERSION
                });
        };
        this.delayedRemovalMarketSelections = new Map();
        this.delayedRemovalMarketHandlers = new Map();
        this.delayedAddMarkets = new Map();
        this.selectionsStorage = new Map();
        this.applySelectionsChanges = options.throttleTimeout
            ? throttle(options.throttleTimeout, this.updateSelections)
            : this.updateSelections;
        this.condition = options.condition;
        this.productConfig = options.productConfig;
        this.trackEvent = options.trackEvent;
        this.subCategories = new Map(
            [...(options.serverData?.events ?? new Map<string, ContractTypes.SportEvent>()).values()]
                .flatMap((e) => e.subcategories)
                .filter((s): s is ContractTypes.SubCategory => !!s)
                .map((s) => [s.id, s])
        );

        makeObservable<IEventPageRetriever, MarketboardRetrieverMobx>(this, {
            resetCategory: action,
            loadData: action,
            setCategoryId: action,
            onErrorCallback: action,
            add: action,
            change: action,
            remove: action,
            onDelayedMarketRemoval: action,
            updateSelections: action,
            _categoryId: observable
        });
    }

    get categoryId() {
        if (this._categoryId) {
            return this._categoryId;
        }

        const category =
            this.categoryName &&
            this.data.events
                .get(this.eventId)
                ?.categories?.find((category) => category.name.toLowerCase() === this.categoryName);

        return category ? category.id : '';
    }

    setCategoryId = (categoryId: string) => {
        const isPopularCategory = isPopularTab(categoryId);
        const skipFetching = isPopularCategory && !this.categoryName && !this._categoryId;
        if ((this.categoryId === categoryId || skipFetching) && this.condition.value !== DataCondition.ERROR) {
            return;
        }

        this._categoryId = isPopularCategory ? undefined : categoryId;
        this.categoryName = isPopularCategory ? '' : this.categoryName;
        this.loadData();
    };

    resetCategory = () => {
        this._categoryId = undefined;
        this.categoryName = '';
    };

    loadData = () => {
        this.condition.set('EventPageRetriever', DataCondition.LOADING);
        this.subscriptionDisposer();
        this.getData()
            .then((data) => {
                runInAction(() => (this.data = data));
                this.onLoad(data);
                this.subscriptionDisposer = this.subscribe(this.getQuery(data));
            })
            .catch((err) => {
                this.onError(err);
            });
    };

    private trackSocketEvent(clientAction: string, metadata?: ContractTypes.Metadata) {
        if (this.productConfig.isEventPushTelemetryEnabled) {
            const receivedAt = new Date().getTime();
            const publishedAt = metadata?.publishedTime ? new Date(metadata.publishedTime).getTime() : 0;
            const delta = publishedAt ? receivedAt - publishedAt : 0;

            this.trackEvent('LONGSHOT_EVENT', {
                clientAction,
                delta,
                eventId: this.eventId,
                pageName: 'EVENT_PAGE',
                widget: 'EVENT_PAGE_WIDGET',
                widgetVersion: APP_VERSION
            });
        }
    }

    protected subscribe = (params: ContractTypes.SubscriptionPartial) => {
        const event = new this.SportsbookEvent(
            params,
            'event',
            this.initialData,
            this.onDataCallback,
            this.onErrorCallback
        );
        this.trackSocketEvent('SUBSCRIBE');
        return () => {
            event.deactivate();
            this.trackSocketEvent('UNSUBSCRIBE');
        };
    };

    protected getData = async (): Promise<SportsbookData> => {
        if (this.serverData) {
            return Promise.resolve(this.serverData).then((data) => {
                this.serverData = null;
                this.condition.set('EventPageRetriever', DataCondition.LOADED);
                return data;
            });
        }

        const getDataCallback = this._categoryId ? this.getEventCategoryData : this.getEventCategoriesData;
        const data = await getDataCallback();
        if (data) {
            data.events
                .flatMap((e) => e.subcategories)
                .filter((s): s is ContractTypes.SubCategory => !!s && !this.subCategories.has(s.id))
                .forEach((s) => this.subCategories.set(s.id, s));
            this.messageBus.emit('on_event_data', {
                response: data
            });
            this.condition.set('EventPageRetriever', DataCondition.LOADED);
            return formatStadiumResponseData(data, this.eventId, this.subCategories);
        }

        this.condition.set('EventPageRetriever', DataCondition.ERROR);
        throw new Error(`No data found for eventID: ${this.eventId}`);
    };

    protected getQuery = (data: SportsbookData): ContractTypes.SubscriptionPartial => {
        return data.subscriptionPartials;
    };

    private getEventCategoriesData = async (): Promise<ContractTypes.StadiumResponse | void> => {
        return this.Stadium.getEventCategoriesData({
            eventId: this.eventId,
            categoryName: this.categoryName
        });
    };

    private getEventCategoryData = async (): Promise<ContractTypes.StadiumResponse | void> => {
        if (this._categoryId) {
            return this.Stadium.getEventCategoryData({
                eventId: this.eventId,
                categoryId: this._categoryId
            });
        }
    };

    private onErrorCallback = (err: unknown) => {
        this.condition.set('EventPageRetriever', DataCondition.ERROR);
        this.logError({
            statusCode: 'SocketConnectionError',
            description: err instanceof Error ? err.message : JSON.stringify(err),
            eventId: this.eventId,
            widgetVersion: APP_VERSION
        });
    };

    private onDataCallback = (response: ContractTypes.SportsbookPushResponse) => {
        this.add(response.data.add, response.metadata);
        this.change(response.data.change, response.metadata);
        this.remove(response.data.remove, response.metadata);

        if (this.data.markets.size && this.data.events.size && this.data.selections.size) {
            this.condition.set('EventPageRetriever', DataCondition.LOADED);
        }
    };

    private add = (payload: AddPayload, metadata?: ContractTypes.Metadata) => {
        const entities: string[] = [];
        payload.events.forEach((event) => {
            const { id, tags } = event;
            const newEvent = { ...event };
            if (event.eventScorecard?.scorecardComponentId) {
                newEvent.eventScorecard = {
                    ...event.eventScorecard,
                    // There is a problem in BE, they return scoreCards on an event update and this will be fixed with the new API version
                    // TODO: Use scorecards instead of scoreCards when the BE fix it
                    scorecards: event.eventScorecard?.scoreCards
                };
            }
            const currentEventTags = this.data.events.get(this.eventId)?.tags;
            const updatedTags = addUniqueTags(currentEventTags, tags);
            this.data.events.set(id, { ...newEvent, tags: updatedTags });
            entities.push(TRACKED_UPDATE_ENTITIES.ADD.EVENT);
        });

        payload.markets.forEach((m) => {
            const delayedMarketCorrelatedId = this.delayedRemovalMarketHandlers.has(m.correlatedId)
                ? m.correlatedId
                : null;

            if (delayedMarketCorrelatedId) {
                const hasSelections =
                    !this.delayedRemovalMarketSelections.has(m.id) &&
                    [...this.data.selections.values()].some((s) => s.marketId === m.id);

                if (!hasSelections) {
                    this.delayedAddMarkets.set(m.id, m);
                    entities.push(TRACKED_UPDATE_ENTITIES.ADD.MARKET);
                    return;
                }

                const removeDelayedMarket = this.delayedRemovalMarketHandlers.get(delayedMarketCorrelatedId);
                removeDelayedMarket?.();
            }

            this.data.markets.set(m.id, m);
            entities.push(TRACKED_UPDATE_ENTITIES.ADD.MARKET);
        });

        payload.selections.forEach((s) => {
            const delayedAddMarket = this.delayedAddMarkets.get(s.marketId);

            if (delayedAddMarket) {
                const removeDelayedMarket = this.delayedRemovalMarketHandlers.get(delayedAddMarket.correlatedId);
                removeDelayedMarket?.();
                this.data.markets.set(delayedAddMarket.id, delayedAddMarket);
                this.delayedAddMarkets.delete(delayedAddMarket.id);
            }

            this.data.selections.set(s.id, s);
            entities.push(TRACKED_UPDATE_ENTITIES.ADD.SELECTION);
        });

        entities.length && entities.forEach((entity) => this.trackSocketEvent(entity, metadata));
    };

    private change = (payload: UpdatePayload, metadata?: ContractTypes.Metadata) => {
        const entities: string[] = [];
        payload.events.forEach((newEvent) => {
            const existEvent = this.data.events.get(newEvent.id);
            if (existEvent) {
                eventDeepMerge(existEvent, {
                    ...newEvent,
                    eventScorecard: {
                        ...newEvent.eventScorecard,
                        // There is a problem in BE, they return scoreCards on an event update and this will be fixed with the new API version
                        // TODO: Use scorecards instead of scoreCards when the BE fix it
                        scorecards: newEvent.eventScorecard?.scoreCards
                    }
                });
                entities.push(TRACKED_UPDATE_ENTITIES.CHANGE.EVENT);
            }
        });

        payload.markets.forEach((newMarket) => {
            const oldMarket = this.data.markets.get(newMarket.id);
            if (!oldMarket) {
                return;
            }

            marketDeepMerge(oldMarket, newMarket);
            entities.push(TRACKED_UPDATE_ENTITIES.CHANGE.MARKET);
        });

        const selectionsUpdate = new Map<string, ContractTypes.SelectionUpdate>(
            payload.selections.map((s) => [s.id, { ...s }])
        );
        if (selectionsUpdate.size) {
            this.updateSelectionStorage(selectionsUpdate);
            this.applySelectionsChanges();
            entities.push(TRACKED_UPDATE_ENTITIES.CHANGE.SELECTION);
        }

        entities.length && entities.forEach((entity) => this.trackSocketEvent(entity, metadata));
    };

    private remove = (payload: RemovePayload, metadata?: ContractTypes.Metadata) => {
        const entities: string[] = [];
        payload.events.forEach((event) => {
            const existingEvent = this.data.events.get(event);
            if (existingEvent) {
                existingEvent.status = 'FINISHED';
                entities.push(TRACKED_UPDATE_ENTITIES.REMOVE.EVENT);
            }
        });

        payload.markets.forEach((id) => {
            const market = this.data.markets.get(id);

            if (market?.tags?.includes('MicroMarketDelayRemoval')) {
                const { correlatedId } = market;
                market.isSuspended = true;
                this.delayedRemovalMarketHandlers.set(
                    correlatedId,
                    delay(this.productConfig.microMarketsBlinkingConfig.delay, () =>
                        this.onDelayedMarketRemoval(correlatedId, id)
                    )
                );
                entities.push(TRACKED_UPDATE_ENTITIES.REMOVE.MARKET);
                return;
            }

            this.data.markets.delete(id);
            entities.push(TRACKED_UPDATE_ENTITIES.REMOVE.MARKET);
        });

        payload.selections.forEach((id) => {
            const selection = this.data.selections.get(id);
            const market = selection && this.data.markets.get(selection.marketId);

            if (market?.tags?.includes('MicroMarketDelayRemoval')) {
                this.addDelayedRemovalMarketSelections(id, market.id);
                entities.push(TRACKED_UPDATE_ENTITIES.REMOVE.SELECTION);
                return;
            }

            this.data.selections.delete(id);
            this.selectionsStorage.delete(id);
            entities.push(TRACKED_UPDATE_ENTITIES.REMOVE.SELECTION);
        });

        entities.length && entities.forEach((entity) => this.trackSocketEvent(entity, metadata));
    };

    private onDelayedMarketRemoval = (correlatedId: string, marketId: string) => {
        const selections = this.delayedRemovalMarketSelections.get(marketId);

        if (selections) {
            selections.forEach((id) => {
                this.data.selections.delete(id);
                this.selectionsStorage.delete(id);
            });
        }

        this.data.markets.delete(marketId);
        this.delayedRemovalMarketSelections.delete(marketId);
        this.delayedRemovalMarketHandlers.delete(correlatedId);
    };

    private addDelayedRemovalMarketSelections = (selectionId: string, marketId: string) => {
        if (!this.delayedRemovalMarketSelections.has(marketId)) {
            this.delayedRemovalMarketSelections.set(marketId, []);
        }

        const marketSelections = this.delayedRemovalMarketSelections.get(marketId);
        marketSelections && marketSelections.push(selectionId);
    };

    private updateSelectionStorage(update: Map<string, ContractTypes.SelectionUpdate>) {
        update.forEach((u) => this.selectionsStorage.set(u.id, u));
    }

    private updateSelections = () => {
        this.selectionsStorage.forEach((newSelections) => {
            const oldSelection = this.data.selections.get(newSelections.id);

            if (!oldSelection) {
                return;
            }

            selectionDeepMerge(oldSelection, newSelections);
        });
        this.selectionsStorage = new Map<string, ContractTypes.SelectionUpdate>();
    };
}
/* c8 ignore stop */
